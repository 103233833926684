export const env = {
    ['survey.rdata.one' as string]: {
        NEXT_PUBLIC_AMPLITUDE_API_KEY: 'fed6d12aa033e5dd2ce0535703534db2',
    },
    ['msbsurvey.rocketdata.space' as string]: {
        NEXT_PUBLIC_AMPLITUDE_API_KEY: 'c14a6debfbe22db5d13bae9e134c6e88',
    },
};

export const sentryDsn = 'https://6f00c21086824b72273514f576e78e22@sentry.rocketdata.io/48';

export const releaseTag = process.env.RELEASE_TAG ?? 'development';
export const sentryEnvironment = process.env.SENTRY_ENV ?? 'staging';
