var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"r2XtpjHs_R8EwaOI3l8So"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

import {isProduction} from '@/consts';
import {releaseTag, sentryDsn, sentryEnvironment} from '@/env';

Sentry.init({
    enabled: isProduction,
    dsn: sentryDsn,
    environment: sentryEnvironment,
    tracesSampleRate: 1,
    debug: false,
    replaysOnErrorSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    release: releaseTag,
    integrations: [
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
            maskAllInputs: true,
        }),
    ],

    beforeSend(event) {
        // eslint-disable-next-line no-param-reassign
        event.tags = event.tags ?? {};
        // eslint-disable-next-line no-param-reassign
        event.tags['url.host'] = window.location.host;

        return event;
    },
});
